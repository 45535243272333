<template>
  <div class="form-part">
    <Select
      label="Allowed countries"
      :options="options"
      :halfWidth="true"
      :optionalLabel="(option) => option.name"
      optionalValue="id"
      multiple
      :name="'allowedCountries'"
      :selected="brokerData ? brokerData.allowedCountries : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.allowedCountries : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showAllowedCounties"
      @approved="setAllowedCountriesReviewed"
      @input="addAllowedCountry"
    />
    <Select
      label="Prohibited countries"
      :options="options"
      :halfWidth="true"
      :optionalLabel="(option) => option.name"
      optionalValue="id"
      multiple
      :name="'prohibitedCountries'"
      :selected="brokerData ? brokerData.prohibitedCountries : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.prohibitedCountries : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="showProhibitedCounties"
      @approved="setProhibitedCountriesReviewed"
      @input="addProhibitedCountry"
    />

    <Select
      label="Payment options"
      :options="paymentOptions"
      :halfWidth="true"
      :optionalLabel="(option) => option.name"
      optionalValue="id"
      multiple
      :name="'paymentOptions'"
      :selected="brokerData ? brokerData.paymentOptions : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.paymentOptions : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.paymentOptions ? brokerData.paymentOptions : [],
              brokerChanges && brokerChanges.paymentOptions
                ? brokerChanges.paymentOptions
                : []
            )
          : true
      "
    />
    <Select
      label="Account currencies"
      :options="accountCurrencies"
      :halfWidth="true"
      :optionalLabel="(option) => option.name"
      :name="'accountCurrencies'"
      multiple
      optionalValue="id"
      :selected="brokerData ? brokerData.accountCurrencies : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.accountCurrencies : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.accountCurrencies ? brokerData.accountCurrencies : [],
              brokerChanges && brokerChanges.accountCurrencies
                ? brokerChanges.accountCurrencies
                : []
            )
          : true
      "
    />
    <Textarea
      label="Currency pairs in XXX/YYY format"
      halfWidth
      :name="'currencyPairs'"
      :value="brokerData ? brokerData.currencyPairs : ''"
      :changesValue="brokerChanges ? brokerChanges.currencyPairs : undefined"
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.currencyPairs,
              brokerChanges ? brokerChanges.currencyPairs : null
            )
          : true
      "
    />
    <Radio
      :halfWidth="true"
      label="Accepts US clients"
      :values="radioSelections"
      column
      name="acceptsUsClients"
      :selected="brokerData ? brokerData.acceptsUsClients : ''"
      :changesSelected="
        brokerChanges ? brokerChanges.acceptsUsClients : undefined
      "
      :checker="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? 'unset'
          : null
      "
      v-if="
        $route.name === 'brokerReview' || getSelectedTab.title === 'logs'
          ? CompairingService.showField(
              brokerData.acceptsUsClients,
              brokerChanges ? brokerChanges.acceptsUsClients : null
            )
          : true
      "
    />
  </div>
</template>

<script>
import Select from "@/components/formFields/Select";
import Textarea from "@/components/formFields/Textarea";
import Radio from "@/components/formFields/Radio";
import {
  countryOffices,
  paymentOptions,
  accountCurrencies,
} from "@/components/graphQL/brokerQueries";
import _ from "lodash";
import CompairingService from "@/services/CompairingService";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PaymentFields",
  components: {
    Select,
    Textarea,
    Radio,
  },
  data() {
    return {
      radioSelections: [
        { label: "No", value: "NO" },
        { label: "Yes", value: "YES" },
        { label: "Coming Soon", value: "COMING_SOON" },
      ],
      CompairingService: new CompairingService(),
      changedChecker: false,
    };
  },
  props: {
    brokerData: Object,
    brokerChanges: Object,
  },
  apollo: {
    countryOffices: countryOffices,
    paymentOptions: paymentOptions,
    accountCurrencies: accountCurrencies,
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters(["getSelectedTab", "getBrokerData"]),
    showProhibitedCounties() {
      return this.$route.name === "brokerReview" ||
        this.getSelectedTab.title === "logs"
        ? this.CompairingService.showField(
            this.brokerData.prohibitedCountries
              ? this.brokerData.prohibitedCountries
              : [],
            this.brokerChanges && this.brokerChanges.prohibitedCountries
              ? this.brokerChanges.prohibitedCountries
              : []
          )
        : true;
    },
    showAllowedCounties() {
      return this.$route.name === "brokerReview" ||
        this.getSelectedTab.title === "logs"
        ? this.CompairingService.showField(
            this.brokerData.allowedCountries
              ? this.brokerData.allowedCountries
              : [],
            this.brokerChanges && this.brokerChanges.allowedCountries
              ? this.brokerChanges.allowedCountries
              : []
          )
        : true;
    },
    options() {
      let selectedOffices = this.getBrokerData.brokerCountryOffices.map(
        (office) => {
          return { id: office.countryOfficeId };
        }
      );
      return _.differenceBy(this.countryOffices, selectedOffices, "id");
    },
  },
  methods: {
    ...mapMutations([
      "updateCountryOffices",
      "setHiddenProhibitedCounties",
      "setHiddenAllowedCounties",
      "resetCountyOffices",
      "touchCountryOffices",
    ]),
    addAllowedCountry(data) {
      this.resetCountyOffices(true);
      if (this.getSelectedTab.title !== "logs") {
        let arr = data.map((item) => {
          return { countryOfficeId: item.id, status: "ALLOWED" };
        });
        arr.forEach((item) => {
          this.updateCountryOffices(item);
        });
      }
      this.touchCountryOffices(true);
    },
    addProhibitedCountry(data) {
      this.resetCountyOffices(false);
      if (this.getSelectedTab.title !== "logs") {
        let arr = data.map((item) => {
          return { countryOfficeId: item.id, status: "PROHIBITED" };
        });
        arr.forEach((item) => {
          this.updateCountryOffices(item);
        });
      }
      this.touchCountryOffices(true);
    },

    setAllowedCountriesReviewed(data) {
      this.changedChecker = true;
      if (data === "true") {
        this.setHiddenAllowedCounties([
          ...this.brokerChanges?.allowedCountries?.map((item) => item.id),
        ]);
      } else {
        this.setHiddenAllowedCounties([
          ...this.brokerData?.allowedCountries?.map((item) => item.id),
        ]);
      }
      this.touchCountryOffices(true);
    },
    setProhibitedCountriesReviewed(data) {
      this.changedChecker = true;
      if (data === "true") {
        if (this.brokerChanges !== undefined) {
          this.setHiddenProhibitedCounties([
            ...this.brokerChanges.prohibitedCountries.map((item) => item.id),
          ]);
        }
      } else {
        if (this.brokerData !== undefined) {
          this.setHiddenProhibitedCounties([
            ...this.brokerData.prohibitedCountries.map((item) => item.id),
          ]);
        }
      }
      this.touchCountryOffices(true);
    },
  },
  watch: {
    brokerChanges: function () {
      if (!this.changedChecker) {
        this.setHiddenProhibitedCounties([
          ...this.brokerChanges.prohibitedCountries.map((item) => item.id),
        ]);
        this.setHiddenAllowedCounties([
          ...this.brokerChanges.allowedCountries.map((item) => item.id),
        ]);
      }
    },
  },
};
</script>
